:root {
  --font-size: calc(1rem + 0.2vw);
  --measure: 60ch;
  --ratio: 1.61803398875;

  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  --s-1: calc(var(--s0) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-5: calc(var(--s-4) / var(--ratio));

  --border-thin: var(--s-4);
  --border-thick: var(--s-2);

  --line-height: var(--ratio);

  --color-light: #fafafa;
  --color-lightish: #e6e6e6;
  --color-lightish-o:rgba(230, 230, 230, .35);

  --color-dark: #050505;
  --color-dark-o: #0505058c;
  --color-darkish: #404040;

  --color-mid: grey;

  --color-primary:  linear-gradient(45deg, #e85d04, #dc2f02);
  --color-primary-d: #ff5100d9;
  --color-primary-o: #ff510090;

  --border-radius: .75rem;

  --divider-color: lightgrey;
  --divider-hover-color: white;
  --divider-focused-color: var(--color-lightish);

  --font-family-main: 'Noto Sans', sans-serif;
}
