.holdings a {
  text-decoration: underline dotted;
  text-decoration-thickness: var(--s-5);
}

.coin-holdings {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
