.home .blog img {
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 2px 2px var(--color-dark));
}

.home .blog a {
  text-decoration: none;
}

.home a:hover {
  text-decoration: none !important;
}

.home .blog a:hover img {
  filter: drop-shadow(0px 2px 4px var(--color-dark));
}

.home .properties a:hover img {
  filter: drop-shadow(0px 0px 4px var(--color-dark));
}

.home .properties img {
  aspect-ratio: 16/9;
}
