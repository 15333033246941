.nestegg-card {
  cursor: pointer;
  border-radius: var(--border-radius);
}

.nestegg-card .checkbox {
  position: absolute;
  top: .5rem;
  left: .5rem;
}

.nestegg-card img {
  width: 100%;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 3px var(--color-dark));
}

input[type="checkbox"] {
  width: 1.55rem;
  height: 1.55rem;
  margin: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: 1px solid var(--color-lightish);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}

input[type="checkbox"]:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.55rem;
  height: 1.55rem;
  border: 1px solid var(--color-lightish);
  background-color: var(--color-dark);
  background-size: cover;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M9.464 14.535L5.05 10.121 3.636 11.536l5.828 5.828L20.364 6.464 18.95 5.05l-9.486 9.485z'/%3E%3C/svg%3E");
}

.nestegg-card:active {
  transform: scale(0.99);
  box-shadow: 0 0 0 0;
}

.nestegg-card img:hover {
  filter: drop-shadow(0px 0px 4px var(--color-dark));
}

.nestegg-card .image {
  position: relative;
}
