.unit-list {
  margin-block-start: 1.5rem;
}

.unit-list a:hover {
  text-decoration: none !important;
}

.unit-list a:hover img {
  filter: drop-shadow(0px 0px 3px var(--color-dark));
}
