.date-pick .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
  display: flex !important;
  align-items: center;
}
.date-pick .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 svg {
  height: 1rem;
  width: 1rem;
  vertical-align: baseline;
  display: none;
 }

.date-pick .DateInput {
  width: auto !important;
}

.date-pick .DateInput_input__focused {
  border-bottom: 2px solid var(--color-primary-d) !important;
}

.date-pick .DateInput_input {
  max-width: fit-content;
  text-align: left;
  color: var(--color-dark) !important;
  font-size: 1rem !important;
  font-weight: 300!important;
  font-family: 'Noto Sans', sans-serif;
  height: 1rem !important;
  padding: .5rem !important;
  background-color: transparent !important;
}

.date-pick .DateRangePickerInput__withBorder {
  border: none !important;
  background-color: transparent !important;
}

.date-pick .DateInput.DateInput_1 {
  background-color: transparent !important;
}

.date-pick .DateRangePicker_picker {
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
}

.date-pick .CalendarDay__blocked_calendar,
.date-pick .CalendarDay__blocked_calendar:active,
.date-pick .CalendarDay__blocked_calendar:hover {
  text-decoration: line-through;
}

.unit-payment select {
  padding-inline: .5rem;
  background: white;
  width: 100% !important;
  color: var(--color-dark);
  border: none !important;
  font-weight: 300;
  font-size: 1rem;
  padding-block: .25rem;
  font-family: 'Noto Sans', sans-serif;
  border-block-start: 1px solid var(--color-dark-o) !important;
}

.unit-payment input#startDate {
  padding-inline-start: 1rem !important;
}

.unit-show .unit-name {
  margin-block: 0;
  line-height: normal;
}

.unit-show-location {
  margin-block-start: 0;
  font-weight: 300;
  font-size: medium;
}

.unit-show .main-image {
  display: grid;
  gap: .5rem;
  grid-template-columns: repeat(2, 1fr);
}

.unit-show .main-image img:first-child {
  height: 100%;
}

.unit-show .other-images {
  display: grid;
  gap:  .5rem;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
}

.unit-show img {
  aspect-ratio: 1/1;
  object-fit: cover;
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
}

.unit-show .main-image #main-img {
  border-start-start-radius: var(--border-radius);
  border-end-start-radius: var(--border-radius);
}

.unit-show .other-images #second-img {
  border-start-end-radius: var(--border-radius);
}

.unit-show .other-images #fourth-img {
  border-end-end-radius: var(--border-radius);
}

.unit-show .unit-details {
  width: fit-content;
}

.unit-show .unit-details span {
  font-weight: 400;
}

.unit-show .config {
  display: flex;
  margin-block-start: 1rem;
}

.unit-show .config p {
  margin-block: 0;
  font-weight: 300;
  font-size: 1rem;
}

.unit-show .config p + p {
  margin-inline-start: .5rem;
}

.unit-show .unit-payment {
  border: none;
  height: fit-content;
  margin-block-start: 2rem;
  max-width: 20rem;
  border-radius: var(--border-radius);
  background-color: white;
  filter: drop-shadow(0px 0px 4px var(--color-dark));
}

.unit-show .unit-payment h2 {
  margin-block-start: 0;
  line-height: normal;
}

.unit-show .unit-payment .title {
  margin-block-start: 0;
  margin-block-end: .5rem;
  font-size: large;
}

.unit-show .unit-payment select {
  margin-block-end: 1rem;
}

.unit-show .unit-payment .mandatory {
  text-align: center;
  padding: .5rem;
  border-radius: var(--border-radius);
  border: none;
  font-size: medium;
  cursor: pointer;
}

.unit-show .unit-payment .line-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unit-show .unit-payment .item {
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: medium;
  font-weight: 300;
}

.unit-show .unit-payment .last {
  margin-block-end: 0;
  font-weight: bold;
}

.unit-show .unit-payment .dashed-border {
  border-block-start: 1px dashed black;
  padding-block-start: .5rem;
}

.unit-show .unit-payment .line-border {
  border-block-start: 1px solid var(--color-lightish);
  padding-block-start: .5rem;
  margin-block-start: .5rem;
}

.unit-show .unit-payment h3 {
  padding-block-start: .5rem;
  margin-block-start: 0;
}

.unit-show .unit-payment button {
  width: 100%;
}

.unit-show .unit-payment #reward-heading {
  margin-block-start: 1.5rem;
}

.unit-show .more-details {
  text-decoration: underline dotted;
  cursor: pointer;
  text-decoration-thickness: 1px;
}

.unit-show .more-details:hover {
  text-decoration: underline solid;
  cursor: pointer;
  text-decoration-thickness: 1px;
}

.unit-show .ant-btn,
.buy .ant-btn,
.upgrade-btn .ant-btn {
  border: none !important;
  box-shadow: none;
  color: var(--color-light) !important;
  border-radius: var(--border-radius);
  font-size: 1rem;
  height: 3rem !important;
  background-image: var(--color-primary);
  padding-inline: 2rem;
  font-family: var(--font-family-main);
}

.unit-show .ant-btn:hover,
.buy .ant-btn:hover,
.upgrade-btn .ant-btn:hover {
  color: var(--color-light) !important;
}

.unit-show .pass-modal {
  position: absolute;
  background-color: white;
  padding: 1rem;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  z-index: 10;
  width: 18rem;
  left: 0;
  top: 3.5rem;
}

.unit-show .pass-modal-content button {
  background-image: none;
  background-color: transparent;
  height: 2rem;
  text-align: left;
  padding-inline-start: .5rem;
}

.unit-show .pass-modal-content h5 {
  margin: 0;
  margin-inline-start: .5rem;
}

.unit-show .pass-modal-content li:hover {
  background-color: var(--color-lightish);
}

.unit-show .overview p {
  margin-block-start: .25rem;
  margin-block-end: 0;
  font-weight: 300;
  font-size: .95rem;
}

.unit-show .overview h4 {
  margin: 0;
}

.unit-show .overview ul {
  font-size: .95rem;
  margin-block: 0;
}

.unit-show .switcher {
  gap: 3rem;
  flex-wrap: nowrap;
}
