.unit-card {
  border-radius: var(--border-radius);
  background-color: var(--color-light);
  cursor: pointer;
}

.unit-card img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 2px var(--color-dark));
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.unit-card .details {
  padding-inline: .5rem;
}

.unit-card .details p {
  margin: 0;
}

.unit-card .details #unit-config {
  font-weight: 300;
  font-size: .9rem;
}

.unit-card .details p:first-child {
  font-size: .92rem;
  font-weight: 600;
}

.unit-card .details #price-night {
  margin-block-start: 1rem;
  margin-block-end: .5rem;
  font-size: .92rem;
  font-weight: bold;
}

.unit-card:active {
  transform: scale(0.99);
  box-shadow: 0 0 0 0;
}

@keyframes moving-gradient {
  0% { background-position: 100% 0%; }
  100% { background-position: 0% 100%; }
}

.unit-card-placeholder {
  width: 100%;
  height: 350px;
  background-size: 200% 200%;
  background-color: black;
  background-image: linear-gradient(45deg, #ddd 25%, #eee 50%, #ddd 75%);
  animation: moving-gradient 2s linear infinite;
  border-radius: var(--border-radius);
}
