.payments .link {
  font-weight: bold;
  text-decoration: underline;
}

.payments {
  max-width: 1120px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.payments .bg-image {
  background-image: url('../../assets/Rectangle\ 14.png');
}

.payments a.active {
  text-decoration: underline;
}

.payments .past img {
  width: 6rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: var(--border-radius);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  filter: drop-shadow(0px 0px 1px var(--color-dark));
}

.payments .payment-card:hover {
  background-color: white;
  border-radius: var(--border-radius);
  cursor: pointer;
}
