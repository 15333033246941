.nestegg-list {
  padding-block-start: 2rem;
  border-block-start: 1px solid var(--color-dark-o);
}

.nestegg-list .box {
  text-align: center;
  background-color: white;
  border: none;
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
}

.nestegg-list .grid {
  gap: 1.5rem;
}
