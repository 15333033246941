.upgrade-nest-egg {
  padding: var(--s1);
  max-width: 18rem;
  margin-block-start: 1rem;
  background-color: white;
  filter: drop-shadow(0px 0px 5px var(--color-dark-o));
  border-radius: var(--border-radius);
}

.upgrade-nest-egg p {
  font-weight: 200;
  margin-block: 0;
  margin-block-start: var(--s-3);
  margin-block-end: var(--s0);
}

.upgrade-nest-egg .burn-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--s2);
}

.upgrade-nest-egg .amount-btn {
  display: flex;
  gap: var(--s0);
}

.upgrade-nest-egg .amount-btn button {
  background-image: none;
  color: var(--color-dark);
  background-color: transparent;
  font-size: small;
}

.upgrade-nest-egg .amount-btn button:hover {
  text-decoration: underline;
}

form.upgrade-nest-egg .nestegg-details {
  margin-block-start: 1.5rem;
  border-block-start: 1px solid var(--color-lightish);
}

.upgrade-nest-egg .mandatory {
  text-align: center;
  padding: .5rem;
  margin-block-end: 1rem;
  background-color: var(--color-dark);
  color: var(--color-light);
  font-size: var(--s0);
}

.upgrade-nest-egg .upgrade-btn button {
  width: 100%;
}

.upgrade-nest-egg input {
  border-block-end: 1px solid var(--color-lightish);
  border-block-start: none;
  border-inline: none;
  border-radius: 3px;
  padding-inline-start: .25rem !important;
  font-family: var(--font-family-main) !important;
  font-weight: 300;
  font-size: medium;
  width: 8ch !important;
  padding-block: 0 !important;
}
