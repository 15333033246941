/* TenantProfileModal.css */
.tenant-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.tenant-modal-content {
  background-color: white;
  padding: 1.5rem;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  max-width: 80%;
  position: relative;
}

.tenant-modal #modal-close {
  border: none !important;
  padding: .5rem !important;
  margin-block-start: 0;
  max-width: fit-content;
  position: absolute;
  left: 88%;
  top: 1rem;
  cursor: pointer;
}

.tenant-modal h5 {
  margin: 0;
  width: max-content;
}

.tenant-modal .profile.box.center {
  padding: 0;
}

.tenant-modal h2:first-child {
  line-height: normal;
}
