.unit-show .modal {
  position: fixed;
  background-color: white;
  padding: 20px;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 8px var(--color-dark-o));
  z-index: 10;
  left: 50%;
  top: calc(50% - 4rem);
  transform: translate(-100%, 0%);

}

.unit-show .modal h5 {
  margin: 0;
  width: max-content;
}

.unit-show .modal p {
  margin-block-end: 0;
}

.unit-show .modal button {
  border: none !important;
  margin-block-start: 0;
  padding-inline: 0;
  max-width: fit-content;
  background-image: none;
  background-color: transparent;
}

.unit-show .modal-text {
  width: 30rem;
  margin-block-start: 0;
  font-size: medium;
  font-weight: 300;
}

.unit-show .modal svg {
  height: 1.5rem;
  width: 1.5rem;
}
