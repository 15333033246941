.flash-message {
  width: fit-content;
  background-color: white;
  color: var(--color-dark);
  filter: drop-shadow(0px 1px 4px var(--color-dark));
  padding: .75rem;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1000;
  border: none;
}

.flash-message p {
  margin-block-start: .25rem;
}

.flash-message .close {
  width: fit-content;
  align-self: flex-end;
}

.flash-message button {
  border: none !important;
  font-weight: 400;
  padding-block: .05rem !important;
  padding-inline: .25rem !important;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
}

.flash-message button:hover svg {
  fill: var(--color-dark);
}
