.buy-details {
  display: flex;
  flex-direction: column;
  margin-block-start: 2rem;
  align-items: flex-end;
}

.buy-details p {
  margin-block: .5rem;
}

.buy a {
  text-decoration: underline dotted;
  text-decoration-thickness: var(--s-5);
}

.buy-details img {
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  width: 80%;
}

.buy .ant-btn {
  width: 100%;
}

.buy {
  max-width: 1120px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.buy .bg-image {
  background-image: url('../../assets/Rectangle\ 16.png');
}
