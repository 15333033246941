.small-search-bar {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--color-lightish);
  border-radius: 3rem;
  padding-inline-end: .3rem;
  padding-block: .25rem;
  border-inline-end: 1px solid var(--color-lightish);
  cursor: pointer;
  filter: drop-shadow(0px 1px 1px var(--color-dark-o));
}

.small-search-bar .location {
  padding-inline: 1.25rem;
  background-color: transparent;
  display: flex;
}

.small-search-bar .dates {
  background-color: transparent;
  display: flex;
}

.small-search-bar .location:hover {
  background-color: var(--color-darkish-o);
}

.small-search-bar .title {
  font-weight: bold;
  font-size: .9rem;
}

.small-search-bar .divider {
  background-color: var(--color-lightish);
  width: 1px;
  margin-block: .1rem;
  margin-inline-start: 1rem;
}

.small-search-bar .search {
  background-image: var(--color-primary);
  padding: .6rem;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
}

.small-search-bar .search svg {
  vertical-align: super;
  height: 100%;
}

.small-search-bar .guests {
  padding-inline: 1.25rem;
}

.small-search-bar .guests .description {
  font-weight: 400;
  font-size: .9rem;
  color: var(--color-dark-o);
}

.small-search-bar {
  width: fit-content;
}

.small-search-bar:hover {
  filter: drop-shadow(0px 1px 2px var(--color-dark-o));
}
