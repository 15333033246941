.upgrade .switcher {
  padding-block-end: 2rem;

}

.upgrade .switcher .center p:last-child {
  margin-block-end: 0;
}

.upgrade {
  margin-block-end: 1rem;
  max-width: 1120px;
  margin-inline-end: auto;
  margin-inline-start: auto;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upgrade a {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  text-decoration-thickness: var(--s-5);
}

.upgrade .bg-image {
  background-image: url('../../assets/Rectangle\ 15.png');

}
