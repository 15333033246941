.logo {
  width: var(--s4);
}

.logo h1 {
  font-size: var(--s1);
  margin: 0;
  width: fit-content;
  white-space: nowrap;
}

.logo {
  font-family: 'Comfortaa', cursive;
  font-size: 2.25rem;
}

.logo .active {
 text-decoration: none;
}

.logo a:hover {
  text-decoration: none !important;
}

.header-wrapper.header-home .logo h1 {
  color: white;
  padding-block-start: .5rem;
}
