.hamburger-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-dark-o);
  padding-inline-end: .35rem;
  padding-inline-start: .75rem;
  border-radius: 3rem;
  width: 3.75rem;
  z-index: 100;
}

.hamburger-menu:hover, .hamburger-menu.active {
  background-color: white;
  filter: drop-shadow(0px 1px 2px var(--color-dark-o));
}

.hamburger-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: .75rem;
  min-width: 1rem;
}

.hamburger-icon .line {
  background-color: var(--color-dark);
  height: 2px;
  width: 100%;
  transition: all 0.3s ease-out;
}

.hamburger-icon .line-open:first-child {
  transform: rotate(45deg);
  background-image: var(--color-primary);
  translate: 0 .32rem;
}

.hamburger-icon .line-open.mid {
  background-image: none;
  background-color: transparent;
}

.hamburger-icon .line-open:last-child {
  transform: rotate(-45deg);
  background-image: var(--color-primary);
  translate: 0 -.31rem;
}

.hamburger-menu .menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 2.75rem;
  right: 0;
  background-color: white;
  z-index: 1;
  color:var(--color-dark);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  font-size: .95rem;
}

.hamburger-menu .menu-item {
  padding-block: .75rem;
  padding-inline: 1.25rem;
  padding-inline-end: 6rem;
  cursor: pointer;
  color: var(--color-dark);
  text-wrap: nowrap;
}

.hamburger-menu .menu-item:last-child {
  border-bottom: none;
}

.hamburger-menu .menu-item:hover {
  color: var(--color-primary);
}

.hamburger-menu:hover .line {
  background-image: var(--color-primary);
}

.hamburger-menu:hover .line-open.mid {
  background-image: none;
  background-color: transparent;
}

.hamburger-menu .ant-btn {
  border: none !important;
  font-size: var(--font-size);
  padding-block: .8rem !important;
  padding-inline: 1.25rem !important;
  box-shadow: none;
  font-size: .95rem;
  font-weight: bold;
  margin-block-start: 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-start-end-radius: var(--border-radius);
  border-start-start-radius: var(--border-radius);
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  border-block-end: 1px solid var(--color-lightish) !important;
}

.hamburger-menu .ant-btn:hover {
  background-image: var(--color-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.header-wrapper.header-home .hamburger-menu {
 border: 1px solid white;
}

.header-wrapper.header-home .hamburger-menu .line {
  background-color: white;
 }

 .header-wrapper.header-home .hamburger-menu svg {
  fill: white;
}

.header-wrapper.header-home .hamburger-menu:hover svg {
  fill: black;
}

.header-wrapper.header-home .hamburger-menu.active svg {
  fill: black;
}

.menu-list .active-link {
  text-decoration: underline;
}
