.landing .blog img {
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 2px 2px var(--color-dark));
}

.landing .blog a {
  text-decoration: none;
}

.landing a:hover {
  text-decoration: none !important;
}

.landing .blog a:hover img {
  filter: drop-shadow(0px 2px 4px var(--color-dark));
}

.landing .b-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Default to two columns */
  gap: 2rem;
  margin-block-start: 0.5rem;
}

@media (max-width: 768px) {
  .landing .b-grid {
    grid-template-columns: 1fr; /* Switch to one column on smaller screens */
  }
}
