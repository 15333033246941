.home-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* Add this line */
  justify-content: center; /* Add this line */
  padding-block-end: 5.5rem;
  height:45vh;
}

.home-banner .home-search-bar {
  border: none;
}

.bg-image.banner {
  filter: drop-shadow(0px 0px 8px var(--color-dark));
  position: relative;
  width: 100%;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
  display: flex; /* Add this line */
  flex-direction: column; /* Add this line */
}

 .bg-image.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
  z-index: -1;
}

.social-icons {
  margin-block-start: .5rem;
  display: flex;
  gap: 1.75rem;
}

.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px; /* Size of the circle */
  height: 45px; /* Size of the circle */
  border-radius: 50%; /* Makes it circular */
  background-color: #fff; /* Background color of the circle */
  color: #000; /* Color of the icon */
  text-decoration: none; /* Removes underline from the link */
}

.icon-circle:hover {
  background-color: lightgrey; /* Lighter background on hover */
  color: #282828; /* Darker icon color on hover */
}

.email-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid var(--color-lightish);
  border-radius: 3rem;
  width: 25rem;
  margin-block: .75rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-end: .4rem;
  padding-block: 0.24rem;
}

.email-form .email-input {
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  padding: 0.24rem 1rem;
  padding-inline-start: 1.25rem;
  position: relative;
}

.email-form button.sign-up {
  background-color: var(--color-dark) !important;
  color: var(--color-light) !important;
  font-size: .9rem !important;
  font-weight: 300 !important;
  padding-block: .5rem !important;
  padding-inline: .75rem !important;
  border-radius: 3rem !important;
  height: 90% !important;
  display: flex !important;
  gap: .75rem !important;
  border: none !important;
  align-items: center;
  text-wrap: nowrap;
}

.email-form input {
  border: none;
  background-color: transparent !important;
  color: var(--color-dark);
  width: 15rem;
  font-size: .83rem;
  font-weight: 400;
  font-family: var(--font-family-main);
}

.email-form input:focus {
  outline: none;
}

.email-form:focus-within {
  background-color: white;
}

.email-form:not(:focus-within):hover,
.email-form:not(:focus-within):hover input {
  background-color: lightgrey;
}

@media (max-width: 768px) {
  .email-form {
    width: 15rem;  /* Make the form take full width of the container */
    padding-inline-start: .9rem;
  }

  .email-form .email-input {
    padding: 0;
  }

  .email-form input {
    width: fit-content;
  }
}
