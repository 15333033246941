footer {
  border-block-start: var(--s-5) solid var(--color-lightish);
  background-color: white;
}

footer p {
  margin-inline-end: .5rem;
}

footer .h-footer {
  display: flex;
  justify-content: space-between;
  padding: .5rem;
}

footer .v-footer {
  display: none;
  padding: 1.5rem;
}

footer .v-footer p {
  margin-inline-end: 0;
  margin-block: .3rem
}

@media (max-width: 768px) {
  footer .v-footer {
    display: block;
  }

  footer .h-footer {
    display: none;
  }
}
