.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
}

.spinner .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner .logo .load {
  font-family: 'Comfortaa', cursive;
  white-space: nowrap;
  text-align: center;
  font-size: 3rem;
  margin: 0;
}

.dot {
  animation: fade 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
