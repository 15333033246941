@media (max-width: 1400px) {
  .header, main {
    margin-inline: var(--s3) !important;
  }
}

@media (max-width: 1100px) {
  .header, main {
    margin-inline: var(--s1) !important;
  }
}

@media (max-width: 1000px) {
  .hide-on-small-screen {
    display: none !important;
    transition: .7s;
  }

  .buy-details {
    align-items: center !important;
  }

  .buy-details img,
  .buy-details .buy-button {
    width: 60% !important;
  }

  .show-on-small-screen {
    display: inherit;
    transition: .7s;
  }
}

@media (max-width: 745px) {
  .unit-show .main-image {
    display: revert !important;
    gap: 0 !important;
    grid-template-columns: none !important;
  }

  .hide-on-med-screen {
    display: none !important;
    transition: .7s;
  }

  .unit-show .main-image img {
    border-radius: var(--border-radius) !important;
  }

}

@media (max-width: 800px) {
  .header, main {
    margin-inline: var(--s0) !important;
  }

  .hamburger-menu .menu-item:hover {
    color: var(--color-dark) !important;
  }

  .hamburger-menu .hamburger-icon:hover .line {
    background-color: var(--color-dark) !important;
  }

  .hamburger-menu .hamburger-icon:hover .line-open.mid {
    background-color: transparent !important;
  }

  .logo .hide-on-small-screen {
    display: none;
  }
}

@media (min-width: 1001px) {
  .hamburger-menu.hide-on-large-screen {
    display: none;
    transition: .7s;
  }
}

@media (max-width: 400px) {
  .header, main {
    margin-inline: var(--s1) !important;
  }
  main {
    margin-inline: var(--s0) !important;
  }
}
