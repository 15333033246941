.switcher {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s1);
}

.switcher > * {
  flex-grow: 1;
  flex-basis: calc(( 60rem - 100%) * 999);
}

.switcher > :nth-last-child(n+ 5),
.switcher > :nth-last-child(n+ 5) ~ * {
  flex-basis: 100%;
}

.big-switcher > * {
  flex-grow: 1;
  flex-basis: calc(( 70rem - 100%) * 999);
}
