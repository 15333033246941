.home-search-bar {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--color-lightish);
  border-radius: 3rem;
  width: fit-content;
  margin-block-end: .5rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.home-search-bar .location {
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  padding: 0.24rem 1rem;
  padding-inline-start: 1.25rem;
  position: relative;
}

.home-search-bar .location input:focus,
.home-search-bar .start input:focus,
.home-search-bar .end input:focus,
.home-search-bar .guests input:focus {
  outline: none;
}

.home-search-bar .location:focus-within,
.home-search-bar .start:focus-within,
.home-search-bar .end:focus-within,
.home-search-bar .guests:focus-within {
  background-color: white;
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
}

.home-search-bar:focus-within {
  background-color: var(--color-lightish);
}

.home-search-bar .location:not(:focus-within):hover,
.home-search-bar .location:not(:focus-within):hover input,
.home-search-bar .start:not(:focus-within):hover,
.home-search-bar .start:not(:focus-within):hover input,
.home-search-bar .end:not(:focus-within):hover,
.home-search-bar .end:not(:focus-within):hover input,
.home-search-bar .guests:not(:focus-within):hover,
.home-search-bar .guests:not(:focus-within):hover input {
  background-color: lightgrey;
}

.home-search-bar .title {
  cursor: pointer;
}

.home-search-bar .location input {
  padding-inline-start: 0;
}

.home-search-bar .dates {
  display: flex;
}

.home-search-bar .title {
  font-weight: bold;
  font-size: .75rem;
}

.home-search-bar .description {
  font-weight: 300;
  font-size: .75rem;
  color: var(--color-dark-o);
}

.home-search-bar .dates .start,
.home-search-bar .dates .end {
  display: flex;
  flex-direction: column;
  padding: 0.28rem 1.25rem;
  border-radius: 3rem;
}

.home-search-bar .divider {
  background-color: var(--divider-color);
  width: 1px;
  margin-block: .55rem;
}

.home-search-bar .guests {
  display: flex;
  align-items: center;
  padding-inline-end: .4rem;
  padding-block: 0.24rem;
  border-radius: 3rem;
}

.home-search-bar .guests input {
  width: 6rem;
}

.home-search-bar .guests .guest-input {
  display: flex;
  flex-direction: column;
  padding-inline-start: 1.25rem;
  padding-inline-end: .5rem;
}

.home-search-bar button.search {
  background-color: var(--color-dark) !important;
  color: var(--color-light) !important;
  font-size: .9rem !important;
  font-weight: 300 !important;
  padding-block: .5rem !important;
  padding-inline: .75rem !important;
  border-radius: 3rem !important;
  height: 90% !important;
  display: flex !important;
  gap: .75rem !important;
  border: none !important;
  align-items: center;
}

.home-search-bar button.search svg {
  vertical-align: bottom !important;
  height: 100% !important;
}

.home-search-bar input {
  border: none;
  background-color: transparent !important;
  color: var(--color-dark);
  font-size: .83rem;
  font-weight: 400;
  font-family: var(--font-family-main);
}

.home-search-bar .DateRangePicker.DateRangePicker_1 input {
  text-align: center;
  padding: 0 !important;
  width: 6rem !important;
}

.home-search-bar .DateRangePickerInput__withBorder {
  border: none !important;
}

.home-search-bar .DateRangePicker.DateRangePicker_1 .DateInput_input {
  height: auto !important;
  padding: 0 !important;
  font-size: .83rem !important;
  line-height: normal !important;
  vertical-align: baseline !important;
}


.home-search-bar .DateRangePicker.DateRangePicker_1 .DateRangePickerInput, .home-search-bar .DateRangePicker.DateRangePicker_1 .DateInput {
  background-color: transparent !important;
}

.home-search-bar .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 svg {
 height: 1rem;
 width: 1rem;
}

.home-search-bar .DateRangePicker.DateRangePicker_1 {
  color: var(--color-dark) !important;
  font-size: x-small;
}

.home-search-bar .DateInput.DateInput_1 {
  width: fit-content;
}

.DayPickerKeyboardShortcuts_buttonReset.DayPickerKeyboardShortcuts_buttonReset_1.DayPickerKeyboardShortcuts_show.DayPickerKeyboardShortcuts_show_2.DayPickerKeyboardShortcuts_show__bottomRight.DayPickerKeyboardShortcuts_show__bottomRight_3 {
  display: none !important;
}

.CalendarDay__selected_span {
background: var(--color-primary-o) !important;
color: var(--color-dark-o)!important;
border: 1px solid var(--color-light) !important;
}

.CalendarDay__selected_span:hover {
  background: var(--color-primary-d) !important;
  color: var(--color-dark) !important;
  border: 1px solid var(--color-light) !important;
  }

.CalendarDay__selected {
  background: var(--color-primary) !important;
  color: var(--color-light) !important;
  border: 1px solid var(--color-light) !important;
}

.CalendarDay__selected:hover {
  background: var(--color-lightish-o) !important;
  color: var(--color-dark) !important;

}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--color-primary-o) !important;
  border: 1px solid var(--color-light) !important;
  color: var(--color-darkish) !important;
}

.DateRangePicker_picker,
.DayPicker_transitionContainer,
.DayPicker__withBorder {
  border-radius: 1rem !important;
}

.DateInput_fang.DateInput_fang_1 {
  top: 2.5rem !important;
}

.DateRangePicker_picker {
  top: 3.1rem !important;
}
