.header {
  padding-block-end: .5rem;
  padding-block-start: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: var(--s5);
  transition: .7s;
  position: relative;
}

.header.header-shrink {
  max-width: 1120px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.header-wrapper {
  border-block-end: var(--s-5) solid var(--color-lightish);
  background-color: white;
  z-index: 10;
  position: relative;
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.searchbar0-enter {
  opacity: 0;
}

.searchbar0-enter-active {
  opacity: 1;
  transition: opacity 100ms;
}

.searchbar0-exit {
  opacity: 1;
}

.searchbar0-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}

.searchbar1-enter {
  opacity: 0;
  transform: scale(0.9);
}

.searchbar1-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 600ms;
}

.searchbar1-exit {
  opacity: 1;
}

.searchbar1-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 300ms;
}

.header-wrapper.header-home {
  background-color: transparent;
  border: none;
}
