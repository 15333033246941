.no-account {
  margin-block-start: 5rem;
}

.no-account div {
  text-align: center;
  margin-block-start: 1rem;
}

.no-account button {
  margin-block-start: 1rem;
}

.profile.box.center h2:first-child {
  margin-block-start: 0;
}

.profile form button {
  margin-block-start: 1rem;
}

.profile form label {
  font-size: 1rem;
  padding-block-end: .25rem;
}

.profile form input {
  margin-block-start: .25rem;
  margin-block-end: .5rem;
  border: 1px solid lightgray;
  border-radius: var(--border-radius);
  padding-block: .83rem;
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 300;
  font-size: 1rem;
  padding-inline-start: .75rem;
  background-color: white;
}

.profile {
  border: none;
  background-color: transparent;
  margin-block-start: 0;
  max-width: 1120px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.profile button:last-child {
  border: none !important;
  box-shadow: none;
  text-align: center;
  height: 3rem;
  color: var(--color-light);
  border-radius: var(--border-radius) !important;
  font-size: medium;
}

.profile button:last-child:hover {
  color: var(--color-light) !important;
}

.profile a.active {
  text-decoration: underline;
}



.profile .sidebar {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile .sidebar p {
  font-size: .92rem;
  margin-block-start: .25rem;
}

.profile .sidebar h3 {
  margin-block-end: 0;
}

.profile .info-item {
  font-size: .9rem;
  opacity: .5;
  margin: 0;
}
