.desktop {
  display: none;
}

.mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  height: 100vh;
}

@media (min-width: 750px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}

.mobile a:hover {
  text-decoration: underline solid !important;
  text-decoration-thickness: var(--s-5);
}

.mobile a {
  text-decoration: underline solid !important;
  text-decoration-thickness: var(--s-5);
}
