.account .account-tile {
  background-color: white;
  border: 1px solid var(--color-lightish);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  height: max-content;
}

.account-tile p {
  font-size: 1rem;
}

.account .switcher > * {
  flex-grow: 1;
  flex-basis: calc(( 50rem - 100%) * 999);
}

.account {
  max-width: 1120px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.account a:hover {
  text-decoration: none !important;
}
