.beta-testing a.active {
  text-decoration: underline;
}

.beta-testing {
  max-width: 1120px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}


.beta-testing .bg-image {
  background-image: url('../../assets/Rectangle\ 12.png');
}
