@import './config/index.css';
@import './primitives/index.css';

body {
  margin: 0;
  background-color: var(--color-light);
  color: var(--color-dark);
  font-family: var(--font-family-main);
  font-size: var(--font-size);
  line-height: var(--line-height);
}

button {
  background-image: var(--color-primary);
  color: var(--color-light);
  border-radius: var(--border-radius);
  font-family: var(--font-family-main);
  border: none;
  font-size: 1rem;
  cursor: pointer;
  height: 3rem;
  padding-inline: 2rem;
}

a {
  text-decoration: none;
  color: var(--color-dark);
}

a:hover {
  color: var(--color-primary);
}

main {
  margin-inline: var(--s5);
  transition: .7s;
}

a.active {
  text-decoration: underline dotted;
  text-decoration-thickness: var(--s-5);
}
a:hover {
  text-decoration: underline solid !important;
  text-decoration-thickness: var(--s-5) !important;
}

form {
  display:flex;
  flex-direction: column;
}

form label {
  margin-block-end: 0;
}

form input {
  background: transparent;
  max-width: 100%;
  color: var(--color-dark);
}

form .amount-btn button {
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
  padding: 0 !important;
  max-width: min-content !important;
  height: auto !important;
  text-align: end;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.indent p {
  margin-bottom: var(--s1);
}
.indent p + p {
  text-indent: var(--s0);
  margin-top: calc(-1*var(--s0));
}

img {
  width: 100%;
}

.wallet-name-wrapper img {
  width: 25px;
}

.site-border {
  border: 5px solid var(--color-dark);
  background-color: var(--color-light);
  box-shadow: var(--s-5) var(--s-5) var(--s-5) var(--color-darkish);
  border-radius: var(--border-radius);
}

.gradient-text:hover, .logo img:hover + .gradient-text {
  background-image: var(--color-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.breadcrumb {
  display: 'flex';
  font-size: .83rem;
}

.breadcrumb p {
  display: inline;
  margin-inline-start: .5rem;
}

.bg-image {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  border-start-end-radius: var(--border-radius);
  border-end-end-radius: var(--border-radius);
}

.more-info {
  border: 1px solid var(--color-darkish);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-size: .8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.tooltip {
  position: absolute;
  top: -6rem;
  right: calc(-15ch - (-1.5rem));
  background-color: white;
  border-radius: var(--border-radius);
  padding: .5rem;
  filter: drop-shadow(0px 0px 8px var(--color-dark));
  max-width: 30ch;
  z-index: 5;
}

.tooltip p {
  margin: 0;
  font-size: .83rem;
  text-align: center;
}

.Toastify__toast {
  border-radius: var(--border-radius) !important; /* Set the border-radius value you desire */
}

.ant-modal-content .wallet-menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.ant-modal-content .wallet-name-wrapper {
  display: flex;
  align-items: center;
}

.ant-modal-content li {
  align-items: center;
}

.ant-btn.wallet-button {
  padding: .25rem !important;
  height: unset !important;
  background-color: unset !important;
  color: unset !important;
}
