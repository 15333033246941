.location-modal {
  position: absolute;
  background-color: white;
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  border-radius: 1rem;
  font-size: medium;
  width: max-content;
  padding-inline-end: 1rem;
  top: 4rem;
  left: 0rem;
}

.location-modal li {
  list-style: none;
  padding-inline-start: .5rem;
  padding-inline-end: 3rem;
  margin-block: .25rem;
  cursor: pointer;
}

.location-modal ul {
  padding-inline-start: 1rem;
}

.location-modal li:hover {
  background-color: var(--color-lightish);
}
